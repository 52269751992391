<template>
  <div>
    <b-sidebar
      id="add-bus-sidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      :visible="addBusSidebar"
      right
      no-header
      backdrop
      @hidden="(val) => changeValue(val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ busLocal.id > 0 ? 'Edit Bus' : 'Add Bus' }}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <validation-observer
          ref="simpleRules"
          class="px-2"
        >
          <b-form>
            <b-row class="px-2">
              <b-col md="12">
                <b-form-group
                  label="Bus Name"
                  label-for="bus-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Bus Name"
                    rules="required"
                  >
                    <b-form-input
                      id="bus-name"
                      v-model="busLocal.nama"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Bus Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Seat"
                  label-for="seat"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Seat"
                    rules="required"
                  >
                    <b-form-input
                      id="seat"
                      v-model="busLocal.jumlah_seat"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Seat"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Konfigurasi Seat"
                  label-for="seat-conf"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Konfigurasi Seat"
                    rules="required"
                  >
                    <b-form-input
                      id="seat-conf"
                      v-model="busLocal.konfigurasi_seat"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Konfigurasi Seat"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Status"
                  label-for="status-bus"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <b-form-select
                      id="status-bus"
                      v-model="busLocal.status"
                      :options="options"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Status"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="!busLocal.id"
                md="12"
              >
                <b-form-group
                  label="Status Pembayaran"
                  label-for="status-pembayaran-bus"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Status Pembayaran"
                    rules="required"
                  >
                    <b-form-select
                      id="statu-pembayaran-bus"
                      v-model="busLocal.status_pembayaran"
                      :options="options_pembayaran"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Status Pembayaran"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="busLocal.status_pembayaran === 1"
                md="12"
              >
                <b-form-group
                  label="Harga Pembelian"
                  label-for="harga-pembelian"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Harga Pembelian"
                    rules="required"
                  >
                    <b-form-input
                      id="harga-pembelian"
                      v-model="busLocal.harga"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Harga Pembelian"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="busLocal.id > 0 && busLocal.foto"
                md="12"
              >
                <b-row>
                  <b-col md="12">
                    <label for="preview-image">Preview Foto Sebelumnya: </label>
                  </b-col>
                  <b-col md="12">
                    <b-img
                      id="preview-image"
                      class="mb-2"
                      width="200"
                      :src="busLocal.foto"
                      fluid
                      alt="Preview Image"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Foto Bus"
                  label-for="foto-supir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Foto Bus"
                    rules="required"
                  >
                    <b-form-file
                      id="foto-supir"
                      v-model="busLocal.fotoBis"
                      accept="image/*"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Pilih File"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="submit()"
                >
                  Submit
                </b-button>
                <b-button
                  variant="outline-secondary"
                  @click="changeValue(false)"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BButton, BCol,
  BRow, BSidebar, BFormSelect, BFormFile, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BCol,
    BRow,
    BSidebar,
    BFormSelect,
    BFormFile,
    BImg,
  },
  model: {
    prop: 'addBusSidebar',
  },
  props: {
    addBusSidebar: {
      type: Boolean,
      required: true,
    },
    bus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      busLocal: {
        id: null,
        nama: null,
        jumlah_seat: null,
        konfigurasi_seat: null,
        foto: null,
        fotoBis: null,
        status: 'aktif',
        status_pembayaran: 1,
      },
      options: [
        { value: 'tidak_aktif', text: 'Tidak Aktif' },
        { value: 'aktif', text: 'Aktif' },
      ],
      options_pembayaran: [
        { value: 1, text: 'Beli Baru' },
        { value: 0, text: 'Sudah Ada' },
      ],
    }
  },
  watch: {
    bus() {
      this.resetTaskLocal()
    },
  },
  methods: {
    resetTaskLocal() {
      this.busLocal = {
        id: this.bus.id,
        nama: this.bus.nama,
        jumlah_seat: this.bus.jumlah_seat,
        konfigurasi_seat: this.bus.konfigurasi_seat,
        status: this.bus.status,
        foto: this.bus.foto,
      }
    },
    changeValue() {
      const data = {
        id: null,
        nama: null,
        jumlah_seat: null,
        konfigurasi_seat: null,
        status: 'aktif',
        foto: null,
        fotoBis: null,
      }
      this.$emit('bus-sidebar-change', data, false)
    },
    submit() {
      if (this.busLocal.id > 0) {
        this.$emit('submit-data-bis', this.busLocal)
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$emit('submit-data-bis', this.busLocal)
          }
        })
      }
    },
  },
}
</script>
<style>
.b-sidebar-body {
  overflow: hidden !important;
}
</style>
