var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"add-bus-sidebar","sidebar-class":"sidebar-lg","bg-variant":"white","visible":_vm.addBusSidebar,"right":"","no-header":"","backdrop":""},on:{"hidden":function (val) { return _vm.changeValue(val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.busLocal.id > 0 ? 'Edit Bus' : 'Add Bus')+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules",staticClass:"px-2"},[_c('b-form',[_c('b-row',{staticClass:"px-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Bus Name","label-for":"bus-name"}},[_c('validation-provider',{attrs:{"name":"Bus Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bus-name","state":errors.length > 0 ? false:null,"placeholder":"Bus Name"},model:{value:(_vm.busLocal.nama),callback:function ($$v) {_vm.$set(_vm.busLocal, "nama", $$v)},expression:"busLocal.nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Seat","label-for":"seat"}},[_c('validation-provider',{attrs:{"name":"Seat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"seat","state":errors.length > 0 ? false:null,"placeholder":"Seat"},model:{value:(_vm.busLocal.jumlah_seat),callback:function ($$v) {_vm.$set(_vm.busLocal, "jumlah_seat", $$v)},expression:"busLocal.jumlah_seat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Konfigurasi Seat","label-for":"seat-conf"}},[_c('validation-provider',{attrs:{"name":"Konfigurasi Seat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"seat-conf","state":errors.length > 0 ? false:null,"placeholder":"Konfigurasi Seat"},model:{value:(_vm.busLocal.konfigurasi_seat),callback:function ($$v) {_vm.$set(_vm.busLocal, "konfigurasi_seat", $$v)},expression:"busLocal.konfigurasi_seat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"status-bus"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"status-bus","options":_vm.options,"state":errors.length > 0 ? false:null,"placeholder":"Status"},model:{value:(_vm.busLocal.status),callback:function ($$v) {_vm.$set(_vm.busLocal, "status", $$v)},expression:"busLocal.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(!_vm.busLocal.id)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Status Pembayaran","label-for":"status-pembayaran-bus"}},[_c('validation-provider',{attrs:{"name":"Status Pembayaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"statu-pembayaran-bus","options":_vm.options_pembayaran,"state":errors.length > 0 ? false:null,"placeholder":"Status Pembayaran"},model:{value:(_vm.busLocal.status_pembayaran),callback:function ($$v) {_vm.$set(_vm.busLocal, "status_pembayaran", $$v)},expression:"busLocal.status_pembayaran"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(_vm.busLocal.status_pembayaran === 1)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Harga Pembelian","label-for":"harga-pembelian"}},[_c('validation-provider',{attrs:{"name":"Harga Pembelian","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"harga-pembelian","type":"number","state":errors.length > 0 ? false:null,"placeholder":"Harga Pembelian"},model:{value:(_vm.busLocal.harga),callback:function ($$v) {_vm.$set(_vm.busLocal, "harga", $$v)},expression:"busLocal.harga"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(_vm.busLocal.id > 0 && _vm.busLocal.foto)?_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('label',{attrs:{"for":"preview-image"}},[_vm._v("Preview Foto Sebelumnya: ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-img',{staticClass:"mb-2",attrs:{"id":"preview-image","width":"200","src":_vm.busLocal.foto,"fluid":"","alt":"Preview Image"}})],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Foto Bus","label-for":"foto-supir"}},[_c('validation-provider',{attrs:{"name":"Foto Bus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"foto-supir","accept":"image/*","state":errors.length > 0 ? false:null,"placeholder":"Pilih File"},model:{value:(_vm.busLocal.fotoBis),callback:function ($$v) {_vm.$set(_vm.busLocal, "fotoBis", $$v)},expression:"busLocal.fotoBis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.changeValue(false)}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }