<template>
  <div>
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              @click="addBusSidebar = true"
            >
              + Tambah Data Bis
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                class="invoice-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="rows"
        responsive
        :fields="columns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchQuery"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder">#{{ data.value }}</span>
        </template>

        <!-- Column: Id -->
        <template #cell(penghasilan)="data">
          <span class="text-warning">{{ data.value.total_penghasilan | formatRupiah }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="data.value === 'aktif' ? 'light-success' : 'light-danger'"
          >
            {{ data.value === 'aktif' ? 'Aktif' : 'Tidak Aktif' }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(menu)="data">

          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'bus-services', params: { id: data.item.id } }">
                <span class="align-middle ml-50">Service</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'bus-suku-cadang', params: { id: data.item.id } }">
                <span class="align-middle ml-50">Suku Cadang</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'bus-assets', params: { id: data.item.id } }">
                <span class="align-middle ml-50">Assets</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'bus-pajak', params: { id: data.item.id } }">
                <span class="align-middle ml-50">Pajak</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <template #cell(action)="data">

          <div class="text-nowrap">
            <b-button-group>
              <b-button
                :id="`bus-row-${data.item.id}-edit-icon`"
                size="sm"
                variant="warning"
                @click="editBus(data.item, true)"
              >
                Edit
              </b-button>

              <b-button
                :id="`bus-row-${data.item.id}-jual-icon`"
                size="sm"
                variant="danger"
                @click="removeData(data.item.id)"
              >
                Jual
              </b-button>
              <b-tooltip
                title="Jual"
                :target="`bus-row-${data.item.id}-jual-icon`"
              />
            </b-button-group>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <!-- <span class="text-muted">Showing entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalBus"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
      v-model="jualBis"
      title="Jual Bis"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <validation-observer
        ref="simpleRules"
        class="px-2"
      >
        <b-form>
          <b-form-group
            label="Keterangan"
            label-for="keterangan-jual"
          >
            <b-form-input
              id="keterangan-jual"
              v-model="dataJualBis.keterangan"
              type="text"
              required
            />
          </b-form-group>
          <b-form-group
            label="Harga Jual"
            label-for="harga-jual"
          >
            <b-form-input
              id="harga-jual"
              v-model="dataJualBis.hargaJual"
              type="number"
              required
            />
          </b-form-group>
          <b-form-group
            label="Foto Nota"
            label-for="foto-jual"
          >
            <validation-provider
              #default="{ errors }"
              name="Foto Bus"
              rules="required"
            >
              <b-form-file
                id="foto-jual"
                v-model="dataJualBis.fotoNota"
                accept="image/*"
                placeholder="Pilih File"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <AddBusContent
      v-model="addBusSidebar"
      :bus="dataEditBus"
      @bus-sidebar-change="editBus"
      @submit-data-bis="submitData"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge,
  BDropdown, BDropdownItem, BPagination, BTooltip, BButtonGroup,
  VBToggle, BModal, BFormGroup, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import AddBusContent from './Add.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BButtonGroup,
    BFormGroup,
    BBadge,
    BModal,
    BFormFile,

    AddBusContent,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'ID',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Nama Bis',
          key: 'nama',
          sortable: true,
        },
        {
          label: 'Seat',
          key: 'jumlah_seat',
        },
        {
          label: 'Konfigurasi Seat',
          key: 'konfigurasi_seat',
        },
        {
          label: 'Penghasilan',
          key: 'penghasilan',
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
        },
        {
          label: 'Menu',
          key: 'menu',
        },
        {
          label: 'Action',
          key: 'action',
        },
      ],
      perPage: 5,
      totalBus: 0,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      rowsData: [],
      searchTerm: '',
      statusOptions: [
        'Aktif',
        'Tidak Aktif',
      ],
      jualBis: false,
      dataJualBis: {
        id: null,
        hargaJual: 0,
        keterangan: null,
        fotoNota: null,
      },
      addBusSidebar: false,
      dataEditBus: {
        id: null,
        nama: null,
        jumlah_seat: null,
        konfigurasi_seat: null,
        status: 'aktif',
        status_pembayaran: 0,
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    rows() {
      if (this.statusFilter !== null) {
        const statusFilter = this.statusFilter === 'Aktif' ? 'aktif' : 'tidak_aktif'

        return this.rowsData.filter(data => data.status === statusFilter)
      }
      return this.rowsData
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get('/bis').then(response => {
        if (response.data.status === true) {
          this.rowsData = response.data.data
          this.totalBus = response.data.data.length
        }
      })
    },
    editBus(data, value) {
      this.dataEditBus = data
      this.addBusSidebar = value
    },
    submitData(data) {
      if (data.id === null) {
        const formData = new FormData()
        formData.append('nama', data.nama)
        formData.append('jumlah_seat', data.jumlah_seat)
        formData.append('konfigurasi_seat', data.konfigurasi_seat)
        formData.append('status', data.status)
        formData.append('jumlah', data.harga)
        formData.append('status_pembayaran', data.status_pembayaran)
        formData.append('foto', data.fotoBis)
        this.$http.post('/bis', formData).then(response => {
          if (response.data.status === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'BellIcon',
                variant: 'success',
              },
            })

            this.getData()
          }
        })
        this.dataEditBus = {
          id: null,
          nama: null,
          jumlah_seat: null,
          konfigurasi_seat: null,
          status: 'aktif',
        }
      } else {
        const formData = new FormData()
        formData.append('nama', data.nama)
        formData.append('jumlah_seat', data.jumlah_seat)
        formData.append('konfigurasi_seat', data.konfigurasi_seat)
        formData.append('status', data.status)
        formData.append('_method', 'PUT')
        formData.append('foto', data.fotoBis ?? null)
        this.$http.post(`bis/${data.id}`, formData).then(response => {
          if (response.data.status === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'BellIcon',
                variant: 'success',
              },
            })

            this.getData()
          }
        })
      }
      this.addBusSidebar = false
    },
    resetModal() {
      this.dataJualBis = {
        id: this.dataJualBis.id,
        hargaJual: 0,
        keterangan: null,
        fotoNota: null,
      }
    },
    handleOk() {
      const formData = new FormData()
      formData.append('id', this.dataJualBis.id)
      formData.append('keterangan', this.dataJualBis.keterangan)
      formData.append('jumlah', this.dataJualBis.hargaJual)
      formData.append('foto_nota', this.dataJualBis.fotoNota)
      this.$http.post(`bis-penjualan/${this.dataJualBis.id}`, formData).then(response => {
        if (response.data.status === true) {
          this.$swal({
            icon: 'success',
            title: 'Berhasil Terjual!',
            text: response.data.message,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })

          this.getData()
        }
      })
    },
    removeData(id) {
      this.dataJualBis.id = id
      this.jualBis = true
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
</style>
